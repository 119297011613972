@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "GeneralSans-Light";
  src: url("../public/fonts/GeneralSans-Light.otf");
}

@font-face {
  font-family: "GeneralSans-Regular";
  src: url("../public/fonts/GeneralSans-Regular.otf");
}

@font-face {
  font-family: "GeneralSans-Medium";
  src: url("../public/fonts/GeneralSans-Medium.otf");
}

@font-face {
  font-family: "GeneralSans-Semibold";
  src: url("../public/fonts/GeneralSans-Semibold.otf");
}

@font-face {
  font-family: "GeneralSans-Bold";
  src: url("../public/fonts/GeneralSans-Bold.otf");
}

.color-text-default {
  @apply text-brand-light-text-default dark:text-brand-dark-text-default transition-all 
}

.color-text-description {
  @apply text-brand-light-text-description dark:text-brand-dark-text-description transition-all 
}

.color-text-description-subtle {
  @apply text-brand-light-text-subtle-description dark:text-brand-dark-text-subtle-description transition-all 
}

.color-bg-interact {
  @apply bg-brand-light-background dark:bg-brand-dark-background transition-all
}

.focus-border {
  @apply border-[2px] border-solid border-transparent focus:border-brand-accent
}

.button {
  @apply bg-brand-blue dark:bg-brand-accent h-[42px] w-full transition-all rounded-md font-semi text-xs text-white outline-none focus-border flex items-center justify-center cursor-pointer hover:bg-brand-blue-card dark:hover:bg-brand-accent-hover
}

.button-secondary {
  @apply bg-brand-light-card text-brand-light-text-default dark:bg-brand-dark-card h-[42px] w-full transition-all rounded-md font-semi text-xs dark:text-brand-dark-text-default outline-none focus-border flex items-center justify-center cursor-pointer 
}

.small-button {
  @apply h-[36px] px-3 rounded-md font-semi text-xs outline-none flex items-center justify-center cursor-pointer transition-all
}

.warning-button {
  @apply small-button bg-transparent  text-brand-red  border-solid border-[1px] border-brand-red hover:text-white hover:bg-brand-red 
}

.tinted-button {
  @apply small-button bg-brand-light-background hover:bg-brand-light-card-border dark:hover:bg-brand-dark-background dark:bg-brand-blue-card color-text-default 
}

.h1 {
  @apply color-text-default font-semi text-xl mb-10
}

.h2 {
  @apply color-text-default font-semi text-[15px] 
}

.text-default {
  @apply color-text-default font-semi text-[14px]
}

.text-description {
  @apply color-text-description text-xs font-medium transition-all
}

.text-warning {
  @apply text-brand-red text-xs font-medium transition-all
}

.text-description-large {
  @apply color-text-description text-sm font-medium transition-all
}

.text-description-subtle {
  @apply color-text-description-subtle text-xs font-medium transition-all
}

.text-default-small {
  @apply color-text-default font-semi text-xs
}

.text-default-base {
  @apply color-text-default font-semi text-base
}

.text-default-large {
  @apply color-text-default font-semi text-lg
}

.input {
  @apply color-text-default bg-white dark:bg-brand-dark-background h-[42px] rounded-md w-full font-medium text-sm pl-3 outline-none focus-border relative autofill:bg-black autofill:border-black placeholder:text-brand-light-text-subtle-description dark:placeholder:text-brand-dark-text-subtle-description
}

.input-card {
  @apply color-text-default bg-brand-light-card dark:bg-brand-dark-card h-[42px] rounded-md w-full font-medium text-sm pl-3 outline-none focus-border relative autofill:bg-black autofill:border-black
}

.select-container {
  @apply color-text-default color-bg-interact h-[42px] rounded-md px-3 font-semi text-xs whitespace-nowrap cursor-pointer focus-border 
}

.card {
  @apply bg-brand-light-card dark:bg-brand-dark-card rounded-2xl p-6 w-full transition-all text-brand-light-text-default font-medium text-base dark:text-brand-dark-text-default flex flex-col
}

.card-extend-small {
  @apply rounded-lg p-5
}

.card-small {
  @apply card card-extend-small 
}

.table-head {
  @apply text-brand-light-text-subtle-description dark:text-brand-dark-text-subtle-description text-xs col-span-1 mb-4
}

.cell {
  @apply text-sm font-medium color-text-default overflow-hidden whitespace-nowrap text-ellipsis mr-3 flex items-center
}

.status-badge {
  @apply bg-[#D7E0FF] text-brand-accent text-[11px] font-semi px-2.5 py-1 rounded-md inline-block
 }




.switch {
  @apply w-[44px] rounded-full p-[3px] flex cursor-pointer transition-all
}

.switch.on {
  @apply bg-[#22cc88] justify-end transition-all
}

.switch.off {
  @apply bg-brand-light-card-border dark:bg-brand-blue justify-start transition-all
}

.switch div {
  @apply w-5 h-5 bg-white rounded-full dark:bg-brand-dark-text-description
}

.switch.on div {
  @apply dark:bg-brand-light-background
}



.html-light {
  @apply bg-brand-light-background
}

.html-dark {
  @apply bg-brand-dark-background
}


/* 
.switch div {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 200px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
}

 */


